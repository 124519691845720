import { OmniWebService } from '../types';

import { Popover as BootstrapPopover, Tooltip } from 'bootstrap';
import { useEffect, useState } from 'react';

type Props = {
    user: OmniWebService.User;
};
const UserPopover = ({ user }: Props) => {
    // determines if the popover should be displayed or closed on click
    const [shouldShow, setShouldShow] = useState(true);

    const handleMouseDown = (event: MouseEvent) => {
        if (
            event.target instanceof Element &&
            event.target.closest('.popover')
        ) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    // TODO: move to custom hook: useActivatePopovers
    useEffect(() => {
        const popoverTriggerList = Array.from(
            document.querySelectorAll('[data-bs-toggle="popover"]'),
        );

        popoverTriggerList.map(function (popoverTriggerEl) {
            return new BootstrapPopover(popoverTriggerEl);
        });

        // bootstrap sanitizes HTML attributes for popover content.
        // since we need to keep the title attribute on span and h6, we whitelist it
        // more info: https://getbootstrap.com/docs/5.0/getting-started/javascript/#sanitizer
        var whitelist = Tooltip.Default.allowList;
        whitelist.span = ['title'];
        whitelist.h6 = ['title'];
        const popoverEl = document.getElementById('headerPopover');
        if (popoverEl !== null) {
            popoverEl.addEventListener('hidden.bs.popover', function () {
                setShouldShow(true);
            });
            popoverEl.addEventListener('shown.bs.popover', function () {
                setShouldShow(false);
            });
        }
    }, []);

    const togglePopover = () => {
        const popoverEl = document.getElementById('headerPopover');
        if (popoverEl == null) {
            return;
        }

        const popover = BootstrapPopover.getInstance(popoverEl);
        if (popover == null) {
            return;
        }
        shouldShow ? popover.show() : popover.hide();
    };

    const getPopOverContent = (
        imgsrc: string,
        name: string,
        email: string,
    ) => {
        // TODO: This should be modified in the future, in coordination with design team
        // It is not a priority for now
        return `     
        <div class="row align-items-start">
            <div class="col-auto pe-0">
                <img
                src=${imgsrc}
                alt="user"
                width="64"
                height="64"
                ></img>                            
            </div>
            <div class="col text-truncate">
                <div class="row text-truncate">
                    <h6 class="text-truncate" title="${name}">${name}</h6>
                </div>
                <div class="row text-truncate">
                    <span class="fs-7 text-truncate" title="${email}">${email}</span>
                </div>
            </div>
        </div>
        `;
        // TODO: Can there be a case where we get empty strings on prod (e.g. for licenceTitle)?
    };

    return (
        <div className="row gx-0 justify-items-start" id="wide">
            <div className="col-auto mx-0 px-0">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    id="headerPopover"
                    tabIndex={0}
                    className="btn px-0"
                    role="button"
                    data-bs-toggle="popover"
                    data-bs-trigger="focus"
                    data-bs-content={getPopOverContent(
                        user.pictureSmallURL,
                        `${user.firstName} ${user.lastName}`,
                        user.email,
                    )}
                    data-bs-html="true"
                    data-bs-container="#wide"
                    onClick={togglePopover}
                >
                    <img
                        src={user.pictureSmallURL}
                        alt="user"
                        width="32"
                        height="32"
                        className="rounded-circle"
                    ></img>
                </a>
            </div>
        </div>
    );
};

export default UserPopover;
