import { useState, useEffect } from 'react';

import { FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import {
    SiteAnalysis,
    SiteAnalysisBase,
    MetadataConfig,
    SiteAnalysisExecutiveSummaryDetailed,
} from '../../../types';
import useQueryParams from '../../../hooks/useQueryParams';
import useNearestAddress from '../../../hooks/useNearestAddress';
import PdfExportButton from '../../../components/PdfExportButton';
import { PDF_EXPORT_SETTINGS } from '../helpers/getReportDefitintion';
import { getPdfReport } from '../helpers/getPdfReport';
import useOlapSiteAnalysis from '../../../hooks/useOlapSiteAnalysis';
import usePoints from '../../opportunity/hooks/usePoints';
import useCurrentSession from '../../../hooks/useCurrentSession';
import { isStarter } from '../../../helpers/subscription';

type Props = {
    summaryData: SiteAnalysis[];
    geojson: FeatureCollection<Point, GeoJsonProperties>;
    metadata: MetadataConfig;
};

const PdfExport = ({ summaryData, geojson, metadata }: Props) => {
    const { sessionInfo } = useCurrentSession();
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadOptions, setDownloadOptions] = useState(false);
    const [downloadPdfWithPagination, setDownloadPdfWithPagination] = useState(
        true,
    );
    const [shouldShow, setShouldShow] = useState(false);

    const { point, selection, profile } = useQueryParams();

    const { isLoading: isLoadingAddress, address } = useNearestAddress(
        point.lat,
        point.lng,
    );

    const { isLoading: isLoadingPoints, pointsBySurveyByUda } = usePoints(
        geojson,
        metadata,
    );

    const {
        isLoading: isLoadingExecutiveSummaryDetailed,
        data: executiveSummaryDetailed,
    } = useOlapSiteAnalysis({
        q: 'executive-summary-detailed',
    });

    const [shouldDisableButton, setShouldDisableButton] = useState(false);
    useEffect(() => {
        setShouldDisableButton(
            isLoadingAddress ||
                isLoadingPoints ||
                isLoadingExecutiveSummaryDetailed ||
                !pointsBySurveyByUda ||
                !summaryData ||
                !executiveSummaryDetailed ||
                !sessionInfo,
        );

        if (executiveSummaryDetailed) {
            executiveSummaryDetailed.forEach((section) => {
                const demography = (section.data as SiteAnalysisExecutiveSummaryDetailed)
                    .demography as SiteAnalysisBase;
                // Convert string values to number
                demography.total_household_income = +demography.total_household_income;
                demography.total_household_income_projected = +demography.total_household_income_projected;
                demography.total_retail_sales = +demography.total_retail_sales;
                demography.income_per_capita = +demography.income_per_capita;
                demography.income_per_capita_projected = +demography.income_per_capita_projected;

                // Calculate growth
                demography.population_2020_current_growth =
                    demography.population - demography.population_2020;
                demography.population_growth_projected =
                    demography.population_projected_census -
                    demography.population;
                demography.population_avg_annual_growth_projected =
                    demography.population_growth_projected / 5;

                demography.households_2020_current_growth =
                    demography.households - demography.households_2020;
                demography.households_growth_projected =
                    demography.households_projected - demography.households;
                demography.households_avg_annual_growth_projected =
                    demography.households_growth_projected / 5;

                // Calculate the average annual growth rate from 2020 to the current year.
                // This is done by dividing the total growth by the number of years between 2020 and the current year (currently 3 years).
                // Update the divisor when new data is added to reflect the correct number of years.
                demography.population_2020_current_avg_annual_growth =
                    demography.population_2020_current_growth / 3;
                demography.households_2020_current_avg_annual_growth =
                    demography.households_2020_current_growth / 3;
            });
        }
    }, [
        isLoadingPoints,
        isLoadingAddress,
        isLoadingExecutiveSummaryDetailed,
        pointsBySurveyByUda,
        summaryData,
        executiveSummaryDetailed,
        sessionInfo,
    ]);

    const onClickReport = async () => {
        setIsDownloading(true);
        setShouldShow(false);

        if (!pointsBySurveyByUda || !summaryData || !executiveSummaryDetailed || !sessionInfo)
            return;

        await getPdfReport(
            PDF_EXPORT_SETTINGS,
            address,
            selection,
            profile,
            downloadPdfWithPagination,
            point,
            summaryData,
            executiveSummaryDetailed,
            pointsBySurveyByUda,
            geojson,
            isStarter(sessionInfo),
        );
        setIsDownloading(false);
    };

    const menuClass = `dropdown-menu dropdown-menu-end dropdown-menu-fit-content dropdown-menu-min-width  ${
        shouldShow ? 'show' : ''
    }`;

    return (
        <PdfExportButton
            shouldDisableButton={shouldDisableButton}
            isDownloading={isDownloading}
            downloadPdfWithPagination={downloadPdfWithPagination}
            downloadOptions={downloadOptions}
            onClickReport={onClickReport}
            setDownloadPdfWithPagination={setDownloadPdfWithPagination}
            setDownloadOptions={setDownloadOptions}
            menuClass={menuClass}
            setShouldShow={setShouldShow}
        />
    );
};

export default PdfExport;
