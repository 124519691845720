import { OmniWebService } from '../types';

import useQueryParams from '../hooks/useQueryParams';
import useRouteParams from '../hooks/useRouteParams';

import DashboardLogoLink from './DashboardLogoLink';
import OpenInMap from '../pages/selfstorage-prices/OpenInMap';
import VerticalDivider from './VerticalDivider';
import UserPopover from './UserPopover';

type Props = {
    user: OmniWebService.User;
};
const Header = ({ user }: Props) => {
    const { title } = useQueryParams();
    const { frame } = useRouteParams();
    // In case of frame, don't show the header
    if (frame) {
        return null;
    }

    const titleText = title ?? 'New Report';
    return (
        <header className="d-flex align-items-center justify-content-between bg-white px-24 py-16 mb-sm-24 shadow-sm">
            <div className="d-flex align-items-center minw-0">
                <div className="me-8">
                    <DashboardLogoLink />
                </div>
                <VerticalDivider />
                <h6 className="ms-8 text-truncate" title={titleText}>
                    {`Location Profile: ${titleText}`}
                </h6>
            </div>
            <div className="d-flex align-items-center flex-shrink-0">
                <OpenInMap />
                <VerticalDivider />
                <UserPopover user={user} />
            </div>
        </header>
    );
};

export default Header;
