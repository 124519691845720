import { FacilityCommonFilter, FacilitySizeFilter } from '../../../types';
import RatesMode from '../../../enums/RatesMode';
import RatesPeriodType from '../../../enums/RatesPeriodType';
import { RentalCompsPopup } from '../customizeOptions/types';

export const SIZES = ['5x5', '5x10', '10x10', '10x15', '10x20', '10x30'];
export const CHUNK_MULTIPLIERS = [25, 50, 100, 150, 200, 300];
export const FACILITY_TYPES: {
    size: keyof FacilityCommonFilter;
    multiplier: number;
}[] = [
    { size: '5x5' as keyof FacilityCommonFilter, multiplier: 25 },
    { size: '5x10' as keyof FacilityCommonFilter, multiplier: 50 },
    { size: '10x10' as keyof FacilityCommonFilter, multiplier: 100 },
    { size: '10x15' as keyof FacilityCommonFilter, multiplier: 150 },
    { size: '10x20' as keyof FacilityCommonFilter, multiplier: 200 },
    { size: '10x30' as keyof FacilityCommonFilter, multiplier: 300 },
];
//@ts-ignore
export const FIELDS_TO_MAP: [keyof FacilitySizeFilter] = ['avg', 'max', 'min'];
export enum UnitType {
    NonClimateController = 'nonClimateControlled',
    ClimateControlled = 'climateControlled',
    All = 'all',
}
export const UNIT_TYPES = [
    { id: UnitType.All, label: 'All units' },
    { id: UnitType.ClimateControlled, label: 'Climate Controlled (CC)' },
    {
        id: UnitType.NonClimateController,
        label: 'Non-Climate Controlled (NCC)',
    },
] as const;

// Customization options - Settings
export const RATE_PERIODS: RentalCompsPopup.CustomizationOptionsSettingsItem<
    'ratesPeriod'
>[] = [
    { label: 'Per month', value: RatesPeriodType.PerMonth },
    { label: 'Per year', value: RatesPeriodType.PerYear },
];

export const RATE_MODES: RentalCompsPopup.CustomizationOptionsSettingsItem<
    'ratesMode'
>[] = [
    { label: 'Per sq. foot', value: RatesMode.Sqft },
    { label: 'Per unit', value: RatesMode.Chunks },
];

export const RATE_TYPES: RentalCompsPopup.CustomizationOptionsSettingsItem<
    'rateType'
>[] = [
    { label: 'Web', value: 'web' },
    { label: 'Street', value: 'street' },
];

export const TIME_PERIODS: RentalCompsPopup.CustomizationOptionsSettingsItem<
    'timePeriod'
>[] = [
    { label: 'Last month', value: RatesPeriodType.PerMonth },
    { label: 'Last 12 months', value: RatesPeriodType.PerYear },
];

// Customization options - Compare by

export const UNIT_SIZE_MULTI_CHIP_VALUES: RentalCompsPopup.CompareByItemOptions[] = SIZES.map(
    (size) => ({ label: size, value: size }),
);

export const CLIMATE_CONTROL_VALUES: RentalCompsPopup.CompareByItemOptions[] = [
    { label: 'CC', value: true },
    { label: 'Non CC', value: false },
];

export const LEVEL_ACCESS_MULTI_CHIP_VALUES: RentalCompsPopup.CompareByItemOptions[] = [
    { label: 'First floor', value: 'first_floor' },
    { label: 'Drive-up', value: 'drive_up' },
    { label: 'Upper-level', value: 'upper_level' },
    { label: 'Elevator', value: 'elevator' },
];

export const COMPARISON_OPTIONS: RentalCompsPopup.CompareByItem[] = [
    {
        id: 'unit_size',
        title: 'Unit size',
        subtitle: SIZES.join(', '),
        options: UNIT_SIZE_MULTI_CHIP_VALUES,
        selectableOptions: {
            minimum: 1,
            maximum: 6
        },
        areOptionsLimited: true,
    },
    {
        id: 'climate_control',
        title: 'Climate control',
        subtitle: 'CC, Non CC',
        options: CLIMATE_CONTROL_VALUES,
        selectableOptions: {
            minimum: 0,
            maximum: 0
        },
        areOptionsLimited: false,
    },
    {
        id: 'level_and_access',
        title: 'Level and access',
        subtitle: 'First floor, Drive-up, Upper-level, Elevator',
        options: LEVEL_ACCESS_MULTI_CHIP_VALUES,
        selectableOptions: {
            minimum: 1,
            maximum: 2
        },
        areOptionsLimited: true,
    },
];
